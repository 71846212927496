.textBase {
  font-family: "A", "arial", sans-serif;
  color: #000;
}
@media (prefers-color-scheme: dark) {
  .textBase {
    color: #fff;
  }
}

.text {
  composes: textBase;
  display: inline;
}

.title {
  composes: text;
  font-size: 3rem;
  margin: 0.5rem 0;
}

.header {
  composes: text;
  font-size: 2.3rem;
  margin: 1rem 0;
}

.subheader {
  composes: header;
  font-size: 1.5rem;
  margin: 0.75rem 0;
}

.body {
  composes: text;
  font-size: 1.1rem;
  line-height: 1.7rem;
  margin: 0.4rem 0;
}

.link {
  composes: text;
  color: #000;
  font-weight: bold;
  text-decoration: none;
  padding: 0 0.1rem;
  background-repeat: repeat-x;
  background-size: auto 0.3rem;
  background-position-x: 0;
  background-position-y: calc(100%);
  padding-bottom: 1px;
  border-bottom: solid 2px #000;
  box-sizing: border-box;
  cursor: pointer;
}
.link:hover {
  background-image: url("/public/underline-light.svg");
  border-bottom-color: #fff;
  padding-bottom: 3px;
}
@media (prefers-color-scheme: dark) {
  .link {
    color: #fff;
    border-bottom-color: #fff;
  }
  .link:hover {
    background-image: url("/public/underline-dark.svg");
    border-bottom-color: #000;
  }
}

.blockQuote {
  box-sizing: border-box;
  margin: 0.5rem 0;
  padding: 0 1.5rem;
  position: relative;
}
.blockQuote:before {
  position: absolute;
  display: block;
  width: 0.5rem;
  height: 100%;
  left: -0;
  content: "";
  background: var(--var-primary-gradient);
}
.blockQuote > * {
  display: block;
}

.li {
  composes: textBase;
  margin-top: 0.5rem;
}
