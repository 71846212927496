:root {
  /* Defines the gutters on the side of the page. */
  --var-page-padding-horz: 1rem;

  /* Defines the padding above page content. */
  --var-page-padding-top: 1rem;

  --var-primary-gradient: linear-gradient(135deg, #258ea6 20%, #1ed889);
  background: #fff;
}
@media (prefers-color-scheme: dark) {
  :root {
    background: #000;
  }
}
@media (min-width: 480px) {
  :root {
    --var-page-padding-horz: 5rem;
    --var-page-padding-top: 3rem;
  }
}

@font-face {
  font-family: "A";
  font-weight: normal;
  src: url("./assets/fonts/A.otf");
}
@font-face {
  font-family: "A";
  font-weight: bold;
  src: url("./assets/fonts/A-Bold.otf");
}
@font-face {
  font-family: "A";
  font-weight: lighter;
  src: url("./assets/fonts/A-Light.otf");
}
@font-face {
  font-family: "AMono";
  font-weight: normal;
  src: url("./assets/fonts/A-Mono.otf");
}

.container {
  position: relative;
  height: 100%;
}

.App {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background: #fff;
}
@media (prefers-color-scheme: dark) {
  .App {
    background: #000;
  }
}
