.navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  max-height: 4rem;
  backdrop-filter: blur(20px);
  padding: 0 var(--var-page-padding-horz);
}
@media (prefers-color-scheme: dark) {
  .navigation {
    background-color: rgba(30, 30, 30, 0.6);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
}
@media (print) {
  .navigation {
    visibility: hidden;
  }
}

.logo {
  width: 3rem;
  height: 3rem;
  padding: 0.5rem 0;
  display: none;
  margin: 0 1rem 0 -1rem;
}
.logo * {
  fill: #000;
}
@media (prefers-color-scheme: dark) {
  .logo * {
    fill: #fff;
  }
}
@media (min-width: 480px) {
  .logo {
    display: block;
  }
}

.item {
  composes: text from "../typography/typography.module.css";
  display: inline-block;
  line-height: calc(4rem - 1rem);
  vertical-align: center;
  flex: 1;
  text-align: center;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  color: #000;
}
@media (prefers-color-scheme: dark) {
  .item {
    color: #fff;
    font-weight: normal;
  }
}
@media (min-width: 480px) {
  .item {
    font-size: 1.5rem;
  }
}

.itemMatched {
  background-color: rgba(0, 0, 0, 0.1);
}
@media (prefers-color-scheme: dark) {
  .itemMatched {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
