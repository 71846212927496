.page {
  padding: var(--var-page-padding-top) var(--var-page-padding-horz) 5rem;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}
@media (prefers-color-scheme: dark) {
  .page {
    background: #000;
  }
}
.page pre {
  background-color: rgb(40, 41, 54);
  color: white;
  font-family: "AMono";
  position: relative;
}
.page pre:after {
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(40, 41, 54, 0) 80%,
    rgb(51, 53, 68) 95%
  );
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  pointer-events: none;
}
@media (min-width: 480px) {
  .page pre:after {
    content: none;
  }
}
.page pre code {
  margin: 1rem;
  margin-right: 2rem;
  overflow-y: visible;
  overflow-x: auto;
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

.overlayPage {
  background: rgba(255, 255, 255, 0.4) !important;
  backdrop-filter: blur(25px);
}
@media (prefers-color-scheme: dark) {
  .page {
    background: rgba(0, 0, 0, 0.4) !important;
  }
}

.paddedPage {
  padding: 3rem;
}

.page-enter {
  opacity: 0;
  transform: translateX(20%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: translateX(0);
}

.page-exit-active {
  opacity: 0;
  transform: translateX(-20%);
  transition: opacity 300ms, transform 300ms;
}

.card {
  margin: 0.5rem 0;
  padding: 5px;
  display: grid;
  grid-template-columns: 5rem auto;
  grid-template-rows: auto auto;
  column-gap: 0.5rem;
}
@media (min-width: 480px) {
  .card {
    column-gap: 1rem;
  }
}
.card-trans-base {
  transition: all 0.2s ease-in-out;
}
.card-enter {
  opacity: 0;
  transform: translateY(-2rem);
}
.card-enter-active,
.card-exit {
  composes: card-trans-base;
  transform: translateY(0);
  opacity: 1;
}
.card-exit-active {
  composes: card-trans-base;
  transform: translateY(2rem);
  opacity: 0;
}

.cardHeader,
.cardBody {
  grid-column-start: 2;
  grid-row-start: 0;
}

.cardImage {
  position: relative;
  width: 5rem;
  height: 5rem;
  background-size: cover;
  border-radius: 50%;
  grid-column-start: span 1;
  grid-row-start: span 2;
  justify-self: center;
}
.cardImage:after {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: var(--var-primary-gradient, blue);
  border-radius: 50%;
  opacity: 0.5;
}

.overlayHidden {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
}
